// ============================ React ============================
import React, { useCallback, useEffect, useState } from 'react';

// ============================ Redux ============================
import { useAppDispatch, useAppSelector } from '../../store';
import { CurrentTaskNumberSelector, FinalizeSelector, PreviewTasksSelector, TasksSelector } from '../../store/tasks/tasks.selector';
import { isOpened, switchDialog } from '../../store/dialog/dialog.slice';
import { finalize, partialFinalize } from '../../store/tasks/tasks.actions';
import { CandidateCodeSelector } from '../../store/dialog/dialog.selector';
import { CurrentConvergenceIdSelector, CurrentLangSelector } from '../../store/window/window.selector';

// ============================ Styling ============================
import './style.css';

// ============================ Components ============================
import { Avatar, IconButton, Stack, Tooltip, Typography } from '@mui/material';

// ============================ Utils ============================
import { getCurrentUrl } from '../../utils/urls';
import { copyToClipboard } from '../../utils/copy';

// ============================ Types ============================
import { TCollaborator } from '../../pages/editor/index.types';
import { CodeFinalizeResponse, CodePartialFinalizeRequest } from '../../shared/types/tests.type';

// ============================ Constants ============================
import { TOOLTIPS } from './constants';
import { Dialogs } from '../../constants/dialogs.enum';
import { RequestStatuses } from '../../constants/request-statuses.enum';

// ============================ Icons & Images ============================
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import logo from '../../assets/images/Logo.svg';


export type THeaderProps = {
    roomId: string;
    collaborators: TCollaborator[];
};

const lowTimeStyles = {
    color: 'warning.main'
}

export const Header = ({ roomId, collaborators }: THeaderProps) => {
    const tasks = useAppSelector(TasksSelector);
    const previewTasks = useAppSelector(PreviewTasksSelector);
    const dispatch = useAppDispatch();
    const [timeElapsed, setTimeElapsed] = useState<number | null>(null);
    const userCode = useAppSelector(CandidateCodeSelector);
    const taskNumber = useAppSelector(CurrentTaskNumberSelector);
    const convergenceId = useAppSelector(CurrentConvergenceIdSelector);
    const lang = useAppSelector(CurrentLangSelector);
    const finalizeData = useAppSelector(FinalizeSelector);

    const handleFinishResponse = (response: Pick<CodeFinalizeResponse, 'meta'>) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            dispatch(switchDialog(Dialogs.FINALIZE_TIME_OUT));
            dispatch(isOpened());
        };
    };

    const handlePartialFinishResponse = (response: Pick<CodeFinalizeResponse, 'meta'>) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            onFinish();
        };
    };

    const onPartialFinish = async () => {
        if (convergenceId && lang && userCode) {
            const requestData: CodePartialFinalizeRequest = {
                modelId: convergenceId,
                lang: lang,
                userCode: userCode,
                taskId: (taskNumber + 1).toString(),
            };

            const response = await dispatch(partialFinalize(requestData));

            if (response) {
                handlePartialFinishResponse(response);
            };
        };
    };

    const onFinish = async () => {
        if (userCode) {
            const requestData = {
                userCode: userCode
            };
            const response = await dispatch(finalize(requestData));

            if (response) {
                handleFinishResponse(response);
            };
        }
    };

    useEffect(() => {
        if (tasks) {
            if (tasks.timeToSolveSeconds && tasks.alreadyStarted) {
                setTimeElapsed(tasks.timeToSolveSeconds)
            } else {
                setTimeElapsed(0);
            };
        }
    }, [tasks]);

    useEffect(() => {
        if (timeElapsed === 0 && tasks && tasks.alreadyStarted) onPartialFinish();
        const intervalId = setInterval(() => {
            if (timeElapsed && timeElapsed > 0 && !finalizeData) {
                setTimeElapsed((prevTime) => {
                    if (prevTime) {
                        return prevTime - 1;
                    } else {
                        return null;
                    }
                });
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [timeElapsed, tasks, finalize]);

    const formatTime = useCallback((seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }, []);

    const onSharedButtonClick = useCallback(() => {
        if (roomId) {
            copyToClipboard(getCurrentUrl());
        }
    }, [roomId]);

    return (
        <header className="editor-page__header header">
            <img src={logo} alt="BurnLogo" className="header__logo" />
            <div className="header__content">

                <div className="header__buttons">
                    {timeElapsed !== null && timeElapsed >= 0 && tasks && userCode ? (
                        <IconButton className="header__button header__button--without-effects" disableRipple={!!tasks}>
                            <Stack className='header__alarm-info' sx={timeElapsed < 300 ? lowTimeStyles : null} direction='row' spacing={1} justifyContent='center' alignItems='center'>
                                <Typography className="header__text">
                                    {formatTime(timeElapsed)}
                                </Typography>
                                <AlarmOutlinedIcon className="header__icon" />
                            </Stack>
                        </IconButton>
                    ) : (
                        <Tooltip title={TOOLTIPS.timer} arrow>
                            <IconButton className="header__button">
                                <AlarmOutlinedIcon className="header__icon" />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title={TOOLTIPS.theme} arrow>
                        <IconButton className="header__button" disabled={!!tasks}>
                            <DarkModeOutlinedIcon className="header__icon" />
                        </IconButton>
                    </Tooltip>

                </div>

                {!!collaborators && previewTasks === null && (
                    <>
                        <Tooltip title={TOOLTIPS.addPerson} arrow>
                            <IconButton onClick={onSharedButtonClick} className="header__button">
                                <PersonAddAltOutlinedIcon className="header__icon" />
                            </IconButton>
                        </Tooltip>
                        <div className="header__avatars">
                            {collaborators.map((c) => (
                                <Tooltip title={c.displayName ?? 'Name is not set'} key={c.color} arrow>
                                    <Avatar sx={{ bgcolor: c.color }} className="header__avatar">
                                        {c.displayName[0] ?? 'A'}
                                    </Avatar>
                                </Tooltip>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </header>
    );
};
