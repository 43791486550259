import { MAX_ERRORED_REQUESTS } from '../constants/store-constants';
import { TSliceDefaultRejectAction, TSliceDefaultState } from './types/defaults.types';

export const InitialStateDefault: TSliceDefaultState = {
    loaded: 'initializing',
    erroredRequests: 0,
    errorText: '',
    convergenceId: null,
    lang: null
};

export const pendingDefault = (state: TSliceDefaultState) => {
    state.loaded = 'load';
};

export const fulfilledDefault = (state: TSliceDefaultState) => {
    state.loaded = 'loaded';
    state.erroredRequests = 0;
};

export const rejectedDefault = (state: TSliceDefaultState, action: TSliceDefaultRejectAction) => {
    if (action.payload?.reload) {
        location.reload();
    }
    state.erroredRequests = state.erroredRequests + 1;
    state.loaded = state.erroredRequests >= MAX_ERRORED_REQUESTS ? 'critical' : 'error';
    state.errorText = action.payload?.message || 'error.unknown';
};

export const setInitialDefaults = (state: TSliceDefaultState) => {
    state.loaded = InitialStateDefault.loaded;
    state.errorText = InitialStateDefault.errorText;
    state.erroredRequests = InitialStateDefault.erroredRequests;
};

export const clearError = (state: TSliceDefaultState) => {
    state.loaded = 'loaded';
    state.errorText = InitialStateDefault.errorText;
};
