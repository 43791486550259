// TODO fix MonacoEditor options
// ============================ React ============================
import React, { useEffect, useState } from 'react';

// ============================ Styling ============================
import './style.css';

// ============================ Monaco ============================
import MonacoEditor from '@monaco-editor/react';
import { MonacoServices } from 'monaco-languageclient';
import { Registry } from 'monaco-textmate';
import { wireTmGrammars } from 'monaco-editor-textmate';


// ============================ Utils ============================
import { createLSPConnection } from '../../utils/lsp';

// ============================ Types ============================
import { TBeforeMount, TEditorProps, TOnChange, TOnMount, TTheme, TValue } from './index.types';

// ============================ Constants ============================
import { TEMPLATES } from './constants';

// ============================ Assets ============================
import theme from '../../../../assets/themes/light.json';


export const Editor = ({ languageState, lsp, setEditorInstance, convergenceId }: TEditorProps) => {
    const [value, setValue] = useState<TValue>(TEMPLATES[languageState.value].value);

    const loadVSCodeTheme = async (monaco: any, editor: any) => {
        const registry = new Registry({
            getGrammarDefinition: async (scopeName) => {
                if (scopeName == 'source.java') {
                    return {
                        format: 'json',
                        content: await (await fetch(`./tmLanguages/java.tmLanguage.json`)).text(),
                    };
                }
                if (scopeName == 'source.python') {
                    return {
                        format: 'json',
                        content: await (await fetch(`./tmLanguages/magicpython.tmLanguage.json`)).text(),
                    };
                }
                return {
                    format: 'json',
                    content: '',
                };
            },
        });

        const grammars = new Map();
        grammars.set('java', 'source.java');
        grammars.set('python', 'source.python');

        monaco.languages.register({ id: 'java' });
        monaco.languages.register({ id: 'python' });

        await wireTmGrammars(monaco, registry, grammars, editor);
    };

    const beforeMount: TBeforeMount = (monaco) => {
        monaco.editor.defineTheme('custom', theme as TTheme);
    };

    const onMount: TOnMount = (editor, monaco) => {
        MonacoServices.install();
        loadVSCodeTheme(monaco, editor).then(() => monaco.editor.setTheme('custom'));
        setEditorInstance(editor);
    };

    const onChange: TOnChange = (newValue) => {
        setValue(newValue || '');
    };

    useEffect(() => {
        if (!languageState.isExternalChange) {
            setValue(TEMPLATES[languageState.value].value);
        }
    }, [languageState]);

    useEffect(() => {
        let stopConnection: () => void;

        if (lsp) {
            stopConnection = createLSPConnection({
                language: languageState.value,
                selector: [languageState.value],
                id: convergenceId,
            });
        }

        return () => {
            stopConnection?.();
        };
    }, [convergenceId, languageState.value, lsp]);

    return (
        <MonacoEditor
            wrapperProps={{
                className: 'editor__root',
            }}
            // @ts-ignore
            options={{
                automaticLayout: true,
                codeLens: false,
                lightbulb: {
                    enabled: false,
                },
                quickSuggestions: !!lsp,
                padding: {
                    top: 21,
                },
                scrollBeyondLastLine: false,
                minimap: {
                    enabled: false,
                },
                fontFamily: 'JetBrains Mono, "Courier New", monospace',
                fontSize: 14,
                wordWrap: 'on',
            }}
            theme="custom"
            value={value}
            language={languageState.value}
            path={`${process.env.REACT_APP_LSP_ROOT_PATH}.${TEMPLATES[languageState.value].extension}`}
            onChange={onChange}
            onMount={onMount}
            beforeMount={beforeMount}
        />
    );
};
