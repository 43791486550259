// ============================ React ============================
import React from 'react';

// ============================ Styling ============================
import cls from 'classnames';
import './style.css';

// ============================ Components ============================
import { Backdrop, CircularProgress } from '@mui/material';

// ============================ Types ============================
import { TLoaderProps } from './index.types';


export const Loader = ({ open = true, fullScreen = true }: TLoaderProps) => {
    const classes = cls({
        'loader__backdrop--relative': !fullScreen,
    });

    return (
        <Backdrop open={open} classes={{ root: classes }}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};