// ============================ React ============================
import React, { useCallback, useEffect, useState } from 'react';

// ============================ Redux ============================
import { TasksSelector } from '../../../../store/tasks/tasks.selector';
import { useAppSelector } from '../../../../store';

// ============================ Styling ============================
import './style.css';

// ============================ Components ============================
import { Select, MenuItem, SvgIcon, ListItemIcon, Typography, IconButton, Tooltip } from '@mui/material';

// ============================ Types ============================
import { THeaderProps } from './index.types';


// ============================ Constants ============================
import { LANGUAGES } from './constants';

// ============================ Icons & Images ============================
import {
    MotionPhotosAutoOutlined,
    AutoFixHighOutlined,
    CopyAllOutlined,
    RestoreOutlined,
    ZoomOutMapOutlined,
    ZoomInMapOutlined,
} from '@mui/icons-material';
import { Icons } from '../../../../assets/icons/index';


export const Header = ({ language, setLanguage, lsp, setLsp, editorInstance }: THeaderProps) => {
    const [fullScreen, setFullScreen] = useState(false);
    const tasks = useAppSelector(TasksSelector);

    const onFormatButtonClick = useCallback(() => {
        if (editorInstance) {
            editorInstance.getAction('editor.action.formatDocument').run();
        }
    }, [editorInstance]);

    const onLspButtonClick = useCallback(() => {
        setLsp(!lsp);
    }, [lsp, setLsp]);

    const onClearButtonClick = useCallback(() => {
        const model = editorInstance && editorInstance.getModel();

        if (model) {
            model.setValue('');
        }
    }, [editorInstance]);

    const onFullScreenButtonClick = useCallback(() => {
        if (!document.fullscreenElement) {
            document.body.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('fullscreenchange', () => {
            setFullScreen((prev) => !prev);
        });
        return () => {
            document.removeEventListener('fullscreenchange', () => {
                setFullScreen((prev) => !prev);
            });
        };
    }, []);

    return (
        <header className="editor__header header">
            <Select
                className="header__select select"
                value={language}
                onChange={({ target: { value } }) => {
                    setLanguage(value);
                }}
            >
                {LANGUAGES.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        <ListItemIcon className="select__icon-container" sx={{ minWidth: '0px' }}>
                            <SvgIcon
                                component={Icons.languages[value]}
                                className="select__icon"
                                viewBox="0 0 16 16"
                            ></SvgIcon>
                        </ListItemIcon>
                        <Typography>{label}</Typography>
                    </MenuItem>
                ))}
            </Select>
            <Tooltip title={`${lsp ? 'Выключить' : 'Включить'} всплывающие подсказки`} arrow>
                <IconButton className="header__button" onClick={onLspButtonClick}>
                    {lsp ? (
                        <MotionPhotosAutoOutlined className="header__icon_active" />
                    ) : (
                        <MotionPhotosAutoOutlined className="header__icon" />
                    )}
                </IconButton>
            </Tooltip>
            <Tooltip title="Отформатировать" arrow>
                <IconButton className="header__button" onClick={onFormatButtonClick}>
                    <AutoFixHighOutlined className="header__icon" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Скопировать" arrow>
                <IconButton className="header__button" disabled={!!tasks}>
                    <CopyAllOutlined className="header__icon" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Очистить" arrow>
                <IconButton className="header__button" onClick={onClearButtonClick} disabled={!!tasks}>
                    <RestoreOutlined className="header__icon" />
                </IconButton>
            </Tooltip>
            <Tooltip title={fullScreen ? 'Свернуть' : 'Развернуть'} arrow>
                <IconButton className="header__button" onClick={onFullScreenButtonClick} disabled={!!tasks}>
                    {fullScreen ? (
                        <ZoomInMapOutlined className="header__icon" />
                    ) : (
                        <ZoomOutMapOutlined className="header__icon" />
                    )}
                </IconButton>
            </Tooltip>
        </header>
    );
};