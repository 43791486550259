// ============================ React ============================
import React from 'react';

// ============================ Redux ============================
import { useAppSelector } from '../../store';
import { CurrentTaskNumberSelector, TasksSelector } from '../../store/tasks/tasks.selector';

// ============================ Styling ============================
import './style.css';

// ============================ Components ============================
import { Box, Grid, Stack, Typography } from '@mui/material';

// ============================ Other ============================
import { Scrollbar } from 'react-scrollbars-custom';


export const TaskDescriptionContent = () => {
    const task = useAppSelector(TasksSelector);
    const currentTaskNumber = useAppSelector(CurrentTaskNumberSelector);

    return (
        <Scrollbar noDefaultStyles>
            <div
                className='description__content-box'
            >
                <Grid container rowSpacing={2} className='description__content' direction='column'>
                    <Grid item className='description__grid-item description__title-grid-item'>
                        <Typography className='description__title'>
                            {task?.tasks[currentTaskNumber].title}
                        </Typography>
                    </Grid>

                    <Grid item className='description__grid-item'>
                        <Typography className='description__text'>
                            {task?.tasks[currentTaskNumber].description}
                        </Typography>
                    </Grid>

                    <Grid item className='description__grid-item'>
                        <Stack spacing={1}>
                            <Typography className='description__text description__text--bold'>
                                Входные данные
                            </Typography>
                            <Typography className='description__text'>
                                {task?.tasks[currentTaskNumber].inputDescription}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item className='description__grid-item'>
                        <Stack spacing={1}>
                            <Typography className='description__text description__text--bold'>
                                Выходные данные
                            </Typography>
                            <Typography className='description__text'>
                                {task?.tasks[currentTaskNumber].outputDescription}
                            </Typography>
                        </Stack>
                    </Grid>

                    {task?.tasks[currentTaskNumber].examples.map(example => (
                        <Grid key={example.id} item className='description__grid-item'>
                            <Stack spacing={1}>
                                <Typography className='description__text description__text--bold'>
                                    Пример {example.id}
                                </Typography>
                                <Box className='description__text-box'>
                                    <Typography className='description__text'>
                                        <span className='description__text description__text--bold'>Входные данные:</span> {example.input}
                                    </Typography>
                                    <Typography className='description__text'>
                                        <span className='description__text description__text--bold'>Выходные данные:</span> {example.output}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Scrollbar>
    );
};
