import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

const OriginalResizeObserver = window.ResizeObserver;

// @ts-ignore
window.ResizeObserver = function (callback) {
    // @ts-ignore
    const wrappedCallback: TWrappedCallback = (entries, observer) => {
        window.requestAnimationFrame(() => {
            callback(entries, observer);
        });
    };
    // @ts-ignore
    return new OriginalResizeObserver(wrappedCallback);
};

for (let staticMethod in OriginalResizeObserver) {
    if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
        // @ts-ignore
        window.ResizeObserver[staticMethod] = OriginalResizeObserver[staticMethod];
    }
}

loader.config({ monaco });
