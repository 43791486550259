// ============================ React ============================
import React, { FormEventHandler, useState } from 'react';

// ============================ Redux ============================
import { useAppDispatch } from '../../store';
import { switchDialog } from '../../store/dialog/dialog.slice';

// ============================ Components ============================
import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

// ============================ Constants ============================
import { DialogButtonTexts } from '../../constants/dialog-button-texts.enum';
import { DialogFieldLabels } from '../../constants/dialog-field-labels.enum';
import { DialogTitles } from '../../constants/dialog-titles.enum';
import { Dialogs } from '../../constants/dialogs.enum';


export type InputDialogProps = {
    roomId: string;
    onSubmit: (value: string) => void;
    fieldLabel: DialogFieldLabels;
    dialogTitle: DialogTitles;
    buttonText: DialogButtonTexts;
    transitionValue?: string;
    isError?: boolean
};

export const InputDialog = ({ onSubmit, fieldLabel, dialogTitle, buttonText, transitionValue, isError = false }: InputDialogProps) => {
    const [value, setValue] = useState(transitionValue || '');
    const dispatch = useAppDispatch();

    const onSubmitHandler: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        onSubmit(value);
    };

    const onBackButtonClick = () => {
        dispatch(switchDialog(Dialogs.WELCOME_DIALOG));
    }

    return (
        <Box component="form" onSubmit={onSubmitHandler} className='dialog__input-content'>
            <DialogTitle className='dialog__title'>{dialogTitle}</DialogTitle>
            <DialogContent className='dialog__content' sx={{ overflowY: 'unset' }}>
                <TextField
                    error={isError}
                    size='small'
                    id="dialogField"
                    label={fieldLabel}
                    variant="outlined"
                    value={value}
                    onChange={({ target: { value } }) => setValue(value)}
                    fullWidth
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackButtonClick} className='text-transform-none dialog__action-button'>{DialogButtonTexts.BACK}</Button>
                <Button type="submit" variant='contained' className='text-transform-none dialog__action-button'>{buttonText}</Button>
            </DialogActions>
        </Box>
    );
};
