import { createAsyncThunk } from '@reduxjs/toolkit';

import { instance } from '../instance';

import { TExecCodeArgs, TExecCodeReturned } from './types/editor.actions.types';
import { TDefaultAsyncThunkConfig } from '../../shared/types/action.types';
import { createError } from '../../utils/store-utils';
import { EditorEndpoints } from './editor-endpoints.enum';

export const execCode = createAsyncThunk<TExecCodeReturned, TExecCodeArgs, TDefaultAsyncThunkConfig>(
    'editor/execCode',
    async ({ id, language }, { rejectWithValue }) => {
        try {
            let response = await instance.post(`${process.env.REACT_APP_API_URL}${EditorEndpoints.EXEC_CODE}`, { id, lang: language });

            if (response.data.ise === 'Connection refused') {
                response = await instance.post(`${process.env.REACT_APP_API_URL}${EditorEndpoints.EXEC_CODE}`, { id, lang: language });
            }

            if (response.data.ise === 'Connection refused' || response.data.error) {
                return rejectWithValue(createError(new Error('Ошибка запуска кода')));
            }

            return {
                text:
                    response.data.stdErr.replaceAll(
                        /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\.(?=java)/gi,
                        '',
                    ) || response.data.stdOut,
            };
        } catch (error) {
            return rejectWithValue(createError(error));
        }
    },
);
