// ============================ React ============================
import React, { useRef } from 'react';

// ============================ Styling ============================
import './style.css';

type VerticalDragHandleProps = {
    container: React.RefObject<HTMLDivElement>;
};


export const VerticalDragHandle = ({ container }: VerticalDragHandleProps) => {
    const initialPos = useRef(0);
    const initialWidth = useRef(0);

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        if (!container.current) {
            return;
        };

        if(initialWidth.current !== container.current?.offsetWidth) {
            initialWidth.current = container.current?.offsetWidth;
        };
        
        if(initialPos.current !== e.clientX) {
            initialPos.current = e.clientX;
        };
       
    };

    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        
        if (!container.current || !e.clientX) {
            return;
        };
    
        const deltaX = e.clientX - initialPos.current;
        const newWidth = initialWidth.current + deltaX;

        const width = Math.max(newWidth, 0);
    
        if(width > 400) container.current.style.width = `${width}px`;
    };
    
    return <div draggable className='task-description__drag-handle' onDragStart={onDragStart} onDrag={onDrag} />;
};
