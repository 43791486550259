import axios from 'axios';

import { TCreateError } from '../../shared/types/action.types';

export const createError: TCreateError = (error) => {
    if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
            case 401:
                return {
                    message: 'default.error.unauthorized',
                    reload: true,
                };

            case 403:
                return {
                    message: 'default.error.permission',
                    reload: false,
                };

            default:
                return {
                    message: error.response?.statusText || error.message || 'default.error.unknown',
                    reload: false,
                };
        }
    }

    if (error instanceof Error) {
        return {
            message: error.message || 'default.error.unknown',
            reload: false,
        };
    }

    return {
        message: 'default.error.unknown',
        reload: false,
    };
};
