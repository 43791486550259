import { configureStore } from '@reduxjs/toolkit';

import { reducer as appReducer } from './app/app.slice';
import { reducer as editorReducer } from './editor/editor.slice';
import windowReducer from './window/window.slice';
import dialogReducer from './dialog/dialog.slice';
import tasksReducer from './tasks/tasks.slice';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        app: appReducer,
        editor: editorReducer,
        window: windowReducer,
        dialog: dialogReducer,
        tasks: tasksReducer
    },
    devTools: process.env.NODE_ENV === 'development',
});

export type TStoreState = ReturnType<typeof store.getState>;

export type TStoreDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;