// ============================ React ============================
import React, { useCallback, useEffect, useState } from 'react';

// ============================ Redux ============================
import { useAppDispatch, useAppSelector } from '../../store';
import { CandidateCodeSelector, CurrentDialogSelector, isDialogOpenedSelector } from '../../store/dialog/dialog.selector';
import { isOpened, setCode, switchDialog } from '../../store/dialog/dialog.slice';
import { fetchTasks, previewTasks, startTasks } from '../../store/tasks/tasks.actions';
import { switchWindow } from '../../store/window/window.slice';
import { PreviewTasksSelector, TasksErrorSelector } from '../../store/tasks/tasks.selector';
import { clearTasks } from '../../store/tasks/tasks.slice';

// ============================ Styling ============================
import cn from 'classnames';
import './style.css';

// ============================ Components ============================
import { Alert, AlertTitle, Dialog, LinearProgress } from '@mui/material';
import { WelcomeFrame } from '../welcome-frame';
import { InputDialog } from '../input-dialog';
import { InfoDialog } from '../info-dialog';

// ============================ Utils ============================
import { changeSessionSearchParam, getSessionSearchParam } from '../../utils/search-params/session-params';
import { changeInterviewSearchParam, getInterviewPathName } from '../../utils/search-params/interview-params';

// ============================ Sync ============================
import { SyncInstance } from '../../sync';

// ============================ Types ============================
import { TasksPreviewResponse, TasksResponse } from '../../shared/types/tasks-info.type';

// ============================ Constants ============================
import { Dialogs } from '../../constants/dialogs.enum';
import { DialogTitles } from '../../constants/dialog-titles.enum';
import { DialogButtonTexts } from '../../constants/dialog-button-texts.enum';
import { DialogFieldLabels } from '../../constants/dialog-field-labels.enum';
import { RequestStatuses } from '../../constants/request-statuses.enum';
import { Windows } from '../../constants/windows.enum';
import { ButtonVariants } from '../../constants/button-variants.enum';

// ============================ Other ============================
import { v4 as uuid } from 'uuid';


type DialogLayoutProps = {
    onSubmit: (value?: string) => void;
    roomId: string;
    setRoomId: (roomId: string) => void;
    interviewPath: string;
    setInterviewPath: (candidateCode: string) => void;
};

const smDialogSX = {
    maxWidth: '432px',
    boxSizing: 'border-box',
    position: 'relative'
};

const FINALIZE_TIME_OUT_TEXT = 'Спасибо за ваше участие в собеседовании. Время, отведенное для ответов, истекло, и ваши ответы были автоматически отправлены. Мы оценим ваши ответы и свяжемся с вами.';
const FINALIZE_WITH_ANSWERS_TEXT = 'Спасибо за успешное прохождение собеседования! Ваши ответы были отправлены и будут рассмотрены нашей командой. Мы вернёмся к вам с обратной связью.';

export const DialogLayout = ({ onSubmit, roomId, setRoomId, interviewPath, setInterviewPath }: DialogLayoutProps) => {
    const dispatch = useAppDispatch();
    const isDialogOpened = useAppSelector(isDialogOpenedSelector);
    const currentContent = useAppSelector(CurrentDialogSelector);
    const previewTasksData = useAppSelector(PreviewTasksSelector);
    const candidateCode = useAppSelector(CandidateCodeSelector);
    const taskErrors = useAppSelector(TasksErrorSelector);
    const [timer, setTimer] = useState(100);
    const isPreview = currentContent === Dialogs.PREVIEW_DIALOG;
    const isWelcome = currentContent === Dialogs.WELCOME_DIALOG;
    const isAlert = (currentContent === Dialogs.FINALIZE_TIME_OUT || currentContent === Dialogs.FINALIZE_WITH_ANSWERS);

    const clearConvergenceIdStorage = () => localStorage.removeItem('convergenceId');

    const onAlertTimeOut = () => {
        dispatch(switchDialog(Dialogs.WELCOME_DIALOG));
        dispatch(switchWindow(Windows.CODE_WINDOW));

        if (previewTasksData && candidateCode) {
            dispatch(clearTasks());
        };

        if (interviewPath && candidateCode) {
            changeInterviewSearchParam('delete', candidateCode);
            const newInterviewPath = getInterviewPathName(candidateCode);
            if (interviewPath !== newInterviewPath) {
                setInterviewPath(newInterviewPath);
            }
        };

        clearConvergenceIdStorage();

        setTimer(100);

        SyncInstance.close();
    };

    useEffect(() => {
        if (isAlert) {
            const timerId = setTimeout(() => {
                if (timer > 0) {
                    setTimer(timer - 2);
                } else {
                    onAlertTimeOut()
                }
            }, 100);

            return () => clearTimeout(timerId);
        };
        return
    }, [timer, currentContent]);

    const sendSessionConnection = useCallback(
        (username: string) => {
            const roomId = getSessionSearchParam('room');

            if (roomId) {
                setRoomId(roomId);
                onSubmit(username);
            };
        },
        [roomId, onSubmit],
    );

    const sendInterviewConnection = useCallback(
        (userCode: string) => {
            const interviewPath = getInterviewPathName(userCode);

            if (interviewPath) {
                setInterviewPath(interviewPath)
                onSubmit();
            };
        },
        [interviewPath, onSubmit],
    );

    const onCreateButtonClick = useCallback(
        (username: string) => {
            changeInterviewSearchParam('delete');
            if(interviewPath) {
                changeInterviewSearchParam('delete');
            };

            if (!roomId) {
                changeSessionSearchParam('set', 'room', uuid());
            };

            localStorage.setItem('username', username);
            sendSessionConnection(username);

            dispatch(isOpened());
        },
        [roomId],
    );

    const handleTasksResponse = (response: Pick<TasksResponse, 'meta'>) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            dispatch(switchWindow(Windows.INTERVIEW_WINDOW));
        };
    };

    const handlePreviewResponse = (response: TasksPreviewResponse, code: string) => {
        if (response.requestStatus === RequestStatuses.FULFILLED) {
            const interviewPath = getInterviewPathName(code);

            if (!interviewPath) {
                changeInterviewSearchParam('set', code);
            };

            sendInterviewConnection(code);

            if (response.payload && !response.payload.alreadyStarted) {
                clearConvergenceIdStorage();
                dispatch(switchDialog(Dialogs.PREVIEW_DIALOG));
                dispatch(isOpened());
            } else {
                onStartTestingButtonClick(code);
            };

        } else {
            dispatch(isOpened());
        }
    };

    const handleStartResponse = (response: Pick<TasksResponse, 'meta'>, code: string) => {
        if (response.meta.requestStatus === RequestStatuses.FULFILLED) {
            onFetchTasks(code);
        };
    };

    const onStartButtonClick = useCallback(
        (code: string) => {
            dispatch(setCode(code));

            onPreview(code);

            dispatch(isOpened());
        },
        [roomId, onSubmit]
    );

    const onStartTestingButtonClick = (code?: string) => {
        const userCode = code || candidateCode;
        if (userCode) {
            if(candidateCode && !code) dispatch(isOpened());
            onStart(userCode);
        };
    };

    const onPreview = async (code: string) => {
        const requestData = {
            userCode: code
        };
        const response = await dispatch(previewTasks(requestData));

        if (response.payload && 'tasks' in response.payload) {
            const responseData: TasksPreviewResponse = {
                requestStatus: response.meta.requestStatus,
                payload: response.payload
            };

            handlePreviewResponse(responseData, code);
        } else {
            const responseData: TasksPreviewResponse = {
                requestStatus: response.meta.requestStatus,
            };
            handlePreviewResponse(responseData, code);
        }
    };

    const onStart = async (code: string) => {
        const requestData = {
            userCode: code
        };
        const response = await dispatch(startTasks(requestData));

        if (response) {
            const responseData = {
                meta: response.meta
            };

            handleStartResponse(responseData, code)
        };
    };

    const onFetchTasks = async (code: string) => {
        const response = await dispatch(fetchTasks(code));

        if (response) {
            const responseData = {
                meta: response.meta
            };

            handleTasksResponse(responseData)
        };
    };

    const previewButtonsData = [
        { text: DialogButtonTexts.TRIAL_TEST, variant: ButtonVariants.OUTLINED, onClick: () => console.log(DialogButtonTexts.TRIAL_TEST) },
        { text: DialogButtonTexts.START_TESTING, variant: ButtonVariants.CONTAINED, onClick: onStartTestingButtonClick }
    ];

    const renderDialogContent = () => {
        switch (currentContent) {
            case Dialogs.WELCOME_DIALOG:
                return <WelcomeFrame />;
            case Dialogs.CREATE_SESSION_DIALOG:
                return <InputDialog
                    roomId={roomId}
                    fieldLabel={DialogFieldLabels.ENTER_YOUR_NAME}
                    dialogTitle={roomId ? DialogTitles.SESSION_CONNECT : DialogTitles.SESSION_CREATE}
                    buttonText={roomId ? DialogButtonTexts.CONNECT : DialogButtonTexts.CREATE}
                    onSubmit={onCreateButtonClick}
                    transitionValue={localStorage.getItem('username') || ''}
                />;
            case Dialogs.INTERVIEW_DIALOG:
                return <InputDialog
                    roomId={roomId}
                    fieldLabel={DialogFieldLabels.ENTER_YOUR_NUMBER}
                    dialogTitle={DialogTitles.SESSION_INTERVIEW}
                    buttonText={DialogButtonTexts.START}
                    onSubmit={onStartButtonClick}
                    isError={!!taskErrors && taskErrors !== null}
                />;
            case Dialogs.PREVIEW_DIALOG:
                return <InfoDialog
                    dialogTitle={DialogTitles.SESSION_INTERVIEW}
                    buttonTexts={previewButtonsData}
                    taskDuration={previewTasksData?.timeToSolveSeconds || 0}
                    numberOfTasks={previewTasksData?.tasks.length || 0}
                />;
            case Dialogs.FINALIZE_TIME_OUT:
                return <Alert severity="info" sx={smDialogSX} variant="outlined">
                    <LinearProgress variant="determinate" value={timer} color='info' className='dialog__linear-timer' />
                    <AlertTitle>Время вышло.</AlertTitle>
                    {FINALIZE_TIME_OUT_TEXT}
                </Alert>
            case Dialogs.FINALIZE_WITH_ANSWERS:
                return <Alert severity="success" sx={smDialogSX} variant="outlined">
                    <LinearProgress variant="determinate" value={timer} className='dialog__linear-timer' />
                    <AlertTitle>Поздравляем!</AlertTitle>
                    {FINALIZE_WITH_ANSWERS_TEXT}
                </Alert>
            default:
                return null;
        }
    };

    return (
        <Dialog open={isDialogOpened} fullWidth={!isAlert} className={cn('dialog', !isPreview && !isWelcome ? 'small-width-dialog' : null)}>
            {renderDialogContent()}
        </Dialog>
    );
};
