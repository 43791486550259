// ============================ React ============================
import React from 'react';

// ============================ Styling ============================
import './style.css';

// ============================ Components ============================
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';

// ============================ Types ============================
import { PreviewButtonsData } from '../../shared/types/preview-buttons-data.type';

// ============================ Constants ============================
import { DialogTitles } from '../../constants/dialog-titles.enum';
import { ButtonVariants } from '../../constants/button-variants.enum';

export type InfoDialogProps = {
    dialogTitle: DialogTitles;
    buttonTexts: PreviewButtonsData[];
    taskDuration: number;
    numberOfTasks: number;
};

const textSx = {
    color: 'primary.main'
}

const taskRequirments = [
    'Время на решение ограничено. Перед началом убедитесь, что вас ничего не отвлекает. Если таймер запущен, его будет невозможно поставить на паузу.',
    'Проверьте ваше интернет-соединение.',
    'Рекомендуем вам пройти пробный тест перед тем, как начать тестирование. Он займёт не более 5 минут.',
    'Когда время закончится, ваши ответы отправятся на проверку автоматически.'
];

export const InfoDialog = ({ dialogTitle, buttonTexts, taskDuration, numberOfTasks }: InfoDialogProps) => {
    const formattedTime = `Продолжительность: ${taskDuration / 60} мин.`;
    const formattedNumberOfTasks = `Количество заданий: ${numberOfTasks}.`

    return (
        <Box component="div" className='info-dialog__wrapper'>
            <DialogTitle variant='h6'>{dialogTitle}</DialogTitle>
            <DialogContent className='info-dialog__content'>
                <Box>
                    <Typography className='info-dialog__text' variant='body2' sx={textSx}>
                        {formattedTime}
                    </Typography>
                    <Typography className='info-dialog__text' variant='body1' sx={textSx}>
                        {formattedNumberOfTasks}
                    </Typography>
                </Box>
                <ol className='info-dialog__list'>
                    {taskRequirments.map((text, index) => (
                        <li key={index}>
                            <Typography className='info-dialog__text'>{text}</Typography>
                        </li>
                    ))}
                </ol>
                <Divider />
            </DialogContent>
            <DialogActions>
                {buttonTexts.map((button, id) => (
                    <Button 
                        key={id} 
                        type="button" 
                        variant={button.variant || ButtonVariants.TEXT} 
                        onClick={() => button.onClick()}
                        className='text-transform-none dialog__action-button'
                    >
                        {button.text}
                    </Button>
                ))}
            </DialogActions>
        </Box>
    );
};
